import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllTag, tagSearch } from "./TagApi";


const initialState = {
    all_tags : [],
}

//========>>>>>>>>>> GEt All Tags
const fetchAllTags = createAsyncThunk(
    'tags/fetchAllTags',
    async () => {
        try {
            const response = await getAllTag()
            return response
        } catch (error) {
            console.log(error, 'error fetching slice');
            
        }
    }
)



//========>>>>>>>>>>> Search By name
const fetchSearchTag=createAsyncThunk(
    'category/fetch_search_customer',
    async(name:any)=>{
        try {
            const response= await tagSearch(name as any)
         console.log(response)
            return response
        } catch (error) {
            console.log(error)
        }
    }
 )



const TagsSlice = createSlice({
	name: 'tags',
	initialState,
	reducers: {},
	extraReducers: (builder:any) => {
		builder.addCase(fetchAllTags.fulfilled, (state:any, action: any) => {
			state.all_tags=action.payload
		});
		builder.addCase(fetchSearchTag.fulfilled, (state:any, action: any) => {
			state.all_tags=action.payload
		});
	},
});


export {fetchAllTags, fetchSearchTag}
export default TagsSlice.reducer;