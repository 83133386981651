import axios from "axios"
import { BASE_URL } from "../../../../../../ApiBaseUrl"





export const getAllProduct=async()=>{
    const options={
        method:'GET',
        headers:{
            'Content-Type':'application/json'
        }
    }
    try {
        const response=await fetch(`${BASE_URL}products/getall?page_no=1&page_size=20`,options)
        const data= await response.json()
        console.log(data.data)
        return data.data
    } catch (error) {
        console.log(error,"error")
    }
}



export const categorySearch = async (name: string) => {
    console.log(name)
    try {
      const response = await axios.get(`${BASE_URL}category/search?name=${name}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data.data; 
    } catch (error) {
      console.error('Error searching users by name:', error);
      return [];
    }
  };
  


  export const DeleteCategory = async (_Id: string) => {
    try {
        // Make a DELETE request to your API endpoint
        await axios.delete(`${BASE_URL}category/deltecategory/${_Id}`, {
            headers: {
                // 'x-access-token': token, 
            },
        });

        // Refresh the category list or update the UI as needed
        // You can refetch the category data or remove the deleted category from the state
    } catch (error) {
        console.error('Error deleting category:', error);
    }
};






