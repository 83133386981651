import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DeleteCategory, categorySearch, getAllProduct } from "./ProductApi";



const initialState={
 all_product:[]
}


//********************** */ get all
const fetchAllProduct=createAsyncThunk(
    'Product/fetchAllProduct',
    async()=>{
        try {
            const response= await getAllProduct()
            return response
        } catch (error) {
            console.log(error)
        }
    }
 )



  //********************** */ search
const fetchSearchCustomer=createAsyncThunk(
    'category/fetch_search_customer',
    async(name:any)=>{
        try {
            const response= await categorySearch(name as any)
         console.log(response)
            return response
        } catch (error) {
            console.log(error)
        }
    }
 )



  //********************** */ search
const fetchDeleteCategory=createAsyncThunk(
    'category/fetchDeleteCategory', 
    async (_id: any) => {
        try {
            const response= await DeleteCategory(_id as any)
            console.log(response)
			if(Response){
				await getAllProduct()
			 }
		} catch (error) {
			console.log(error)
		}
    }
 )





const productSlice = createSlice({
	name: 'product',
	initialState,
	reducers: {},
	extraReducers: (builder:any) => {
		builder.addCase(fetchAllProduct.fulfilled, (state:any, action: any) => {
			state.all_Product=action.payload
		});
        builder.addCase(fetchSearchCustomer.fulfilled, (state:any, action: any) => {
			state.all_Category=action.payload
		});
	},
});


export {fetchAllProduct, fetchSearchCustomer, fetchDeleteCategory}
export default productSlice.reducer