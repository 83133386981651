import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DeleteCategory, subCategorySearch, getSubCategory } from "./subCategoryApi";



const initialState={
 all_category:[]
}


//********************** */ get all
const fetchSubCategory =createAsyncThunk(
    'subCategory/fetchSubCategory',
    async()=>{
        try {
            const response= await getSubCategory()
            return response
        } catch (error) {
            console.log(error)
        }
    }
 )



  //********************** */ search
const fetchSearchSubCategory=createAsyncThunk(
    'Subcategory/fetch_search_customer',
    async(name:any)=>{
        try {
            const response= await subCategorySearch(name as any)
         console.log(response)
            return response
        } catch (error) {
            console.log(error)
        }
    }
 )



  //********************** */ search
const fetchDeleteCategory=createAsyncThunk(
    'category/fetchDeleteCategory', 
    async (_id: any) => {
        try {
            const response= await DeleteCategory(_id as any)
            console.log(response)
			if(Response){
				await getSubCategory()
			 }
			// return response
		} catch (error) {
			console.log(error)
		}
    }
 )





const subCategorySlice = createSlice({
	name: 'category',
	initialState,
	reducers: {},
	extraReducers: (builder:any) => {
		builder.addCase(fetchSubCategory.fulfilled, (state:any, action: any) => {
			// state.allDriver .push(...action.payload);
			state.all_subCategory=action.payload
            
		});
        builder.addCase(fetchSearchSubCategory.fulfilled, (state:any, action: any) => {
			// state.allDriver .push(...action.payload);
			state.all_subCategory=action.payload
		});
     
	},
});


export {fetchSubCategory, fetchSearchSubCategory , fetchDeleteCategory}
export default subCategorySlice.reducer