import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DeleteCategory, categorySearch, getAllCategory } from "./CategoryApi";



const initialState={
 all_category:[]
}


//********************** */ get all
const fetchAllCategory=createAsyncThunk(
    'category/fetchAllCategory',
    async()=>{
        try {
            const response= await getAllCategory()
            return response
        } catch (error) {
            console.log(error)
        }
    }
 )



  //********************** */ search
const fetchSearchCustomer=createAsyncThunk(
    'category/fetch_search_customer',
    async(name:any)=>{
        try {
            const response= await categorySearch(name as any)
         console.log(response)
            return response
        } catch (error) {
            console.log(error)
        }
    }
 )



  //********************** */ search
const fetchDeleteCategory=createAsyncThunk(
    'category/fetchDeleteCategory', 
    async (_id: any) => {
        try {
            const response= await DeleteCategory(_id as any)
            console.log(response)
			if(Response){
				await getAllCategory()
			 }
		} catch (error) {
			console.log(error)
		}
    }
 )





const CategorySlice = createSlice({
	name: 'category',
	initialState,
	reducers: {},
	extraReducers: (builder:any) => {
		builder.addCase(fetchAllCategory.fulfilled, (state:any, action: any) => {
			state.all_Category=action.payload
		});
        builder.addCase(fetchSearchCustomer.fulfilled, (state:any, action: any) => {
			state.all_Category=action.payload
		});
	},
});


export {fetchAllCategory, fetchSearchCustomer, fetchDeleteCategory}
export default CategorySlice.reducer