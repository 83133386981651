import axios from "axios"
import { BASE_URL } from "../../../../../../ApiBaseUrl"





export const getSubCategory=async()=>{
    const options={
        method:'GET',
        headers:{
            'Content-Type':'application/json'
        }
    }

    try {
        const response=await fetch(`${BASE_URL}subcategory/getAll?page_no=1&page_size=20`,options)
        const data= await response.json()
        console.log(data.data)
        return data.data
    } catch (error) {
        console.log(error,"error")
    }
}



export const subCategorySearch = async (name: string) => {
    console.log(name)
    try {
      const response = await axios.get(`${BASE_URL}subcategory/search?name=${name}`, {
        headers: {
          'Content-Type':'application/json',
        },
      });
      return response.data.data; 
    } catch (error) {
      console.error('Error searching users by name:', error);
      return [];
    }
  };
  


  export const DeleteCategory = async (_Id: string) => {
    try {
        await axios.delete(`${BASE_URL}category/deltecategory/${_Id}`, {
            headers: {
            },
        });

    } catch (error) {
        console.error('Error deleting category:', error);
    }
};






