
import { configureStore } from "@reduxjs/toolkit";
import CategorySlice from "./pages/presentation/corbollie/Products-management/category/categorySlice/CategorySlice";
import subCategorySlice from "./pages/presentation/corbollie/categoryManegement/subCategory/subCategorySlice/subCategorySlice";
import TagSlice from "./pages/presentation/corbollie/categoryManegement/tags/tagSlice/TagSlice";
import ProductSlice from "./pages/presentation/corbollie/Products-management/Product/productSlice/ProductSlice";


const store=configureStore({
    reducer:{
        category:CategorySlice,
        subCategory:subCategorySlice,
        tags : TagSlice,
        product : ProductSlice
       
    }
})

export default store